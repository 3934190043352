import React, { useEffect, useState } from 'react';
// @ts-ignore
import pdfMake from 'pdfmake/build/pdfmake';
// @ts-ignore
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useRouter } from 'next/router';
import Pagination from '@mui/material/Pagination';

import styles from './styles.module.scss';
import DeleteIcon from '@/shared/icons/DeleteIcon';
import CustomDropdown from '@/shared/components/customDropdown/CustomDropdown';
import ButtonAdd from '@/shared/components/buttonAdd/ButtonAdd';
import Button from '@/shared/components/button/Button';
import CustomGrid from '@/shared/components/grid/CustomGrid';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // optional theme
import 'ag-grid-community/styles/ag-theme-alpine.css'; // optional theme
import { Toast, notifyError } from '@/shared/components/toast/Toast';
import { formatCurrency } from '@/shared/functions/currencies';
import CustomModal from '@/shared/components/modal/Modal';
import { ListaComprasEmpty, PlusIcon } from '@/shared/icons';
import { EmptyState } from '@/shared/components/grid/EmptyState';
import { apiRoot, ctCustomer } from '@/shared/client';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { ICustomField } from './ShoppingListDetails';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { IShoppingListDetails, linesItemsRow } from './ShoppingListDetails';
import { BusinessUnit, ClientResponse, ShoppingList } from '@commercetools/platform-sdk';
import { getCredentials } from 'context/appContext/initialLoading';
import ButtonAddPerBranch from './ButtonAddPerBranch';

export type ItemsProduct = {
  sucursal: string;
  Cantidad: number;
  Descuento: string;
  Subtotal: string;
  Total: string;
  isDelete: string;
  key: string;
  id: string;
  idLineItems: string;
};

interface IAddSucursal {
  idShoppingList: string;
  lineItemId: string;
  bussinesId: string;
  price: string;
}

const renderProduct = (params: any) => {
  return (
    <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', height: '100%', gap: '30px' }}>
      <img style={{ width: '45px', height: '45px' }} alt="producto" src={params.value.image} />
      <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
        <span style={{ height: '31px', fontSize: '0.9rem' }}>{params.value.type}</span>
        <span style={{ height: '31px', fontSize: '0.9rem' }}>SKU: {params.value.sku}</span>
      </div>
    </div>
  );
};

export const addSucursalToProduct = async ({ idShoppingList, lineItemId, bussinesId, price }: IAddSucursal) => {
  try {
    const loginEncrypt = getCookieValue(findCookie('login'));
    const login = decryptWithAES(loginEncrypt);
    const { Email, Password, BussinesUnitParent } = ctCredentials(login);
    const customer = ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent });
    const shoppingList = await customer.me().shoppingLists().withId({ ID: idShoppingList }).get().execute();

    if (!shoppingList.statusCode || shoppingList.statusCode >= 300) return notifyError('Algo salio mal');
    const lineItemField = shoppingList.body.lineItems.find((item) => item.id == lineItemId)?.custom?.fields[
      'sucursalItemReturn'
    ];
    lineItemField.push(
      JSON.stringify({
        idSucursal: bussinesId,
        total: price || 0,
        quantity: 1,
        price: price,
      }),
    );
    const updateShoppingList = await customer
      .me()
      .shoppingLists()
      .withId({ ID: idShoppingList })
      .post({
        body: {
          version: shoppingList.body.version,
          actions: [
            {
              action: 'setLineItemCustomField',
              lineItemId: lineItemId,
              name: 'sucursalItemReturn',
              value: lineItemField,
            },
          ],
        },
      })
      .execute();
    if (!updateShoppingList.statusCode || updateShoppingList.statusCode >= 300) return notifyError('Algo salio mal');
    return true;
  } catch (err: any) {
    if (err.body.errors[0].field == 'sucursalItemReturn' && err.body.errors[0].code == 'DuplicateField') {
      notifyError('Esta sucursal ya ha sido agregada');
    } else {
      notifyError(err.message);
    }

    return false;
  }
};

const renderSucursal = (params: any) => {
  const router = useRouter();
  return (
    <div>
      {params?.value?.map((item: ItemsProduct) => {
        return (
          <div className={styles['cell']} style={{ justifyContent: 'flex-start' }}>
            {item?.sucursal ?? <></>}
          </div>
        );
      })}
    </div>
  );
};

export const updateQuantityProductBySucursal = async ({
  action,
  idShoppingList,
  businessId,
  idLine,
  quantity,
}: {
  action: string;
  idShoppingList: string;
  businessId: string;
  idLine: string;
  quantity: number;
}) => {
  let res: ClientResponse<ShoppingList> | null = null;
  const loginEncrypt = getCookieValue(findCookie('login'));
  const login = decryptWithAES(loginEncrypt);
  const { Email, Password, BussinesUnitParent } = ctCredentials(login);
  const customer = ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent });
  const shoppingList = await customer.me().shoppingLists().withId({ ID: idShoppingList }).get().execute();
  if (!shoppingList.statusCode || shoppingList.statusCode >= 300) {
    notifyError('Algo salio mal');
    return res;
  }
  const lineItemShopping = shoppingList.body.lineItems.find((list) => list.id === idLine);
  const fieldsItems = lineItemShopping?.custom?.fields['sucursalItemReturn'];
  const fields = fieldsItems.map((item: any) => JSON.parse(item));
  const fieldSelect = fields.find((item: ICustomField) => item.idSucursal == businessId);
  const fieldFilter = fields.filter((item: ICustomField) => item.idSucursal !== businessId);
  if (action === 'add') {
    fieldSelect.quantity+= quantity;
  } else {
    if (fieldSelect.quantity == 0) {
      fieldSelect.quantity == 0;
    } else {
      fieldSelect.quantity-= quantity;
    }
  }
  fieldSelect.total = fieldSelect.price * fieldSelect.quantity;
  fieldFilter.push(fieldSelect);
  const parseFields = fieldFilter.map((field: any) => JSON.stringify(field));
  try {
    res = await customer
      .me()
      .shoppingLists()
      .withId({ ID: idShoppingList })
      .post({
        body: {
          version: shoppingList.body.version,
          actions: [
            {
              action: 'setLineItemCustomField',
              lineItemId: idLine,
              name: 'sucursalItemReturn',
              value: parseFields,
            },
          ],
        },
        queryArgs: {
          expand: 'lineItems[*].variant',
        },
      })
      .execute();
  } catch (err: any) {
    console.error('error', err);
    notifyError(err.message);
  }
  return res;
};

const renderCount = (params: any) => {
  return (
    <>
      {params?.data?.Sucursal?.map((item: ItemsProduct) => {
        return <ButtonAddPerBranch item={item} params={params} />;
      })}
    </>
  );
};
const renderSubtotal = (params: any) => {
  return (
    <div>
      {params?.data?.Sucursal?.map((item: ItemsProduct) => {
        return <div className={styles['total']}>{item?.Subtotal ?? <></>}</div>;
      })}
    </div>
  );
};

const renderTotal = (params: any) => {
  return (
    <div>
      {params?.data?.Sucursal?.map((item: ItemsProduct) => {
        return <div className={styles['total']}>{item?.Total ?? <></>}</div>;
      })}
    </div>
  );
};

const Grid = ({
  rows,
  selectPrint,
  setPrint,
  setShoppingListDetails,
  subtotal,
  iva,
}: {
  rows: any[];
  setPrint: React.Dispatch<React.SetStateAction<boolean>>
  selectPrint: boolean;
  subtotal: number;
  iva: number;
  setShoppingListDetails: React.Dispatch<React.SetStateAction<IShoppingListDetails>>;
}) => {
  const router = useRouter();
  const [gridApi, setGridApi] = useState<any>();
  const [pagination, setPagination] = useState({
    data: [],
    limit: 5,
    activePage: 1,
    page: 1,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});
  const [params, setParams] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let idTimeout: any;

  const callback_updateQuantityProductBySucursal = async ({
    idShoppingList,
    businessId,
    idLine,
    quantity,
    quantityFromCT,
  }: {
    idShoppingList: string;
    businessId: string;
    idLine: string;
    quantity: number;
    quantityFromCT: number;
  }) => {
    clearTimeout(idTimeout);
    idTimeout = setTimeout(async () => {
      let resUpdate: ClientResponse<ShoppingList> | null = null;
      if (quantity > quantityFromCT) {
        const quantityToUpdate = quantity - quantityFromCT;
        resUpdate = await updateQuantityProductBySucursal({
          action: 'add',
          idShoppingList,
          businessId,
          idLine,
          quantity: quantityToUpdate,
        });
      }
      if (quantity < quantityFromCT) {
        const quantityToUpdate = quantityFromCT - quantity;
        resUpdate = await updateQuantityProductBySucursal({
          action: 'remove',
          idShoppingList,
          businessId,
          idLine,
          quantity: quantityToUpdate,
        });
      }


      const { BussinesUnitParent } = getCredentials();
      if (!resUpdate) return;
      const loadBussinesUnit = await apiRoot
        .businessUnits()
        .get({
          queryArgs: {
            limit: 500,
            where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
          },
        })
        .execute();
      if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300)
        return notifyError('No se cargaron las sucursales');

      const lines = mapLineItems(resUpdate.body, loadBussinesUnit.body.results);
      setShoppingListDetails((prevState) => ({
        ...prevState,
        // @ts-ignore
        shoppingListCT: resUpdate.body,
        linesItems: lines,
      }));
    }, 2000);
  };

  const renderDelete = (params: any) => {
    return (
      <div style={{ position: 'relative', left: '-11px' }}>
        {params?.data?.Sucursal?.map((item: ItemsProduct) => {
          return (
            <div className={styles['cell']}>
              <div>
                {item?.isDelete ? (
                  <Button
                    variant="icon"
                    onClick={() => {
                      setShowModal(true);
                      setParams(params);
                      setItem(item);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const mapLineItems = (shoppingList: ShoppingList, bussinessUnits: BusinessUnit[]) => {
    let sum = 0;
    return shoppingList.lineItems.map((item) => {
      const fieldsItems = item.custom?.fields['sucursalItemReturn'];
      const fields = fieldsItems.map((item: any) => JSON.parse(item));
      let priceUnit = 0;
      let subTotal;
      const sucursalItems = fields.map((item: ICustomField) => {
        const getBusinessName = bussinessUnits.find((address) => address.key === item.idSucursal);
        subTotal = formatCurrency(parseInt(item.total) / 100 );
        const elementTotal = parseInt(item.total) / 100 ;
        const sub = formatCurrency(parseInt(item.total) / 100);
        priceUnit = parseInt(item.price) / 100 ;
        sum = elementTotal + sum;
        setShoppingListDetails((opts) => ({
          ...opts,
          subtotalPrice: sum,
        }));
        return {
          sucursal: getBusinessName?.name,
          Cantidad: item.quantity,
          Descuento: '$0',
          Subtotal: subTotal,
          subTotalNumber: parseInt(item.total) / 100 ,
          Total: sub,
          totalNumber: parseInt(item.total) / 100,
          isDelete: 'delete',
          key: getBusinessName?.key,
          id: getBusinessName?.key,
        };
      });
      const listBusiness = bussinessUnits.map((business) => {
        return {
          value: business.id,
          label: business.name,
        };
      });
      const iva = sum * 0.16;
      setShoppingListDetails((opts) => ({
        ...opts,
        ivaPrice: parseFloat(iva.toFixed(2)),
      }));
      return {
        idShoppingList: shoppingList.id,
        idLine: item.id,
        Producto: {
          image: item.variant?.images?.[0]?.url || '/no_econtrada.webp',
          type: item.name?.["es-MX"] || item?.variant?.key,
          sku: item?.variant?.sku,
          attributes: item.variant?.attributes
        },
        'Precio unitario': formatCurrency(priceUnit!),
        unitPriceNumber: priceUnit! / 100 / 1,
        price: sum,
        Descuento: '$0',
        IVA: '$0',
        Sucursal: sucursalItems,
        listBusiness,
      } as linesItemsRow;
    });
  };

  const [columnDefs] = useState([
    {
      minWidth: 250,
      flex:2,
      field: 'Producto',
      cellRenderer: renderProduct,
      lockPosition: 'left',
      unSortIcon: true,
      type: 'leftAligned',
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        if (valueA.type == valueB.type) return 0;
        return valueA.type > valueB.type ? 1 : -1;
      },
    },
    {
      minWidth: 150,
      flex:2,
      field: 'Precio unitario',
      type: 'rightAligned',
      lockPosition: 'left',
      unSortIcon: true,
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        if (nodeA.data.unitPriceNumber == nodeB.data.unitPriceNumber) return 0;
        return nodeA.data.unitPriceNumber > nodeB.data.unitPriceNumber ? 1 : -1;
      },
    },
    {
      flex:1,
      field: 'Sucursal',
      cellRenderer: renderSucursal,
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        debugger
        if (valueA[0].sucursal == valueB[0].sucursal) return 0;
        return valueA[0].sucursal > valueB[0].sucursal ? 1 : -1;
      },
      autoHeight: true,
      minWidth: 155,
      type: 'leftAligned',
      lockPosition: 'left',
      unSortIcon: true,
    },
    {
      flex:1,
      field: 'Cantidad',
      cellRenderer: renderCount,
      cellRendererParams: { setShoppingListDetails, mapLineItems, callback_updateQuantityProductBySucursal },
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        if (nodeA.data.Sucursal[0].Cantidad == nodeB.data.Sucursal[0].Cantidad) return 0;
        return nodeA.data.Sucursal[0].Cantidad > nodeB.data.Sucursal[0].Cantidad ? 1 : -1;
      },
      minWidth: 150,
      type: 'rightAligned',
      lockPosition: 'left',
      unSortIcon: true,
    },
    // {
    //   flex:2,
    //   field: 'SubTotal',
    //   cellRenderer: renderSubtotal,
    //   comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
    //     if (nodeA.data.Sucursal[0].subTotalNumber == nodeB.data.Sucursal[0].subTotalNumber) return 0;
    //     return nodeA.data.Sucursal[0].subTotalNumber > nodeB.data.Sucursal[0].subTotalNumber ? 1 : -1;
    //   },
    //   minWidth: 150,
    //   type: 'rightAligned',
    //   lockPosition: 'left',
    //   unSortIcon: true,
    // },
    {
      flex:2,
      field: 'SubTotal',
      cellRenderer: renderSubtotal,
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isDescending: boolean) => {
        if (nodeA.data.Sucursal[0].totalNumber == nodeB.data.Sucursal[0].totalNumber) return 0;
        return nodeA.data.Sucursal[0].totalNumber > nodeB.data.Sucursal[0].totalNumber ? 1 : -1;
      },
      minWidth: 150,
      type: 'rightAligned',
      lockPosition: 'left',
      unSortIcon: true,
    },
    {
      flex:1,
      minWidth: 50,
      field: 'Delete',
      cellRenderer: renderDelete,
      cellRendererParams: {
        setShoppingListDetails,
      },
      headerName: '',
      type: 'rightAligned',
      lockPosition: 'left',
      unSortIcon: false,
      sortable: false,
    },
  ]);

  const autoSizeStrategy = {
    type: 'fitCellContents',
  };

  const handleDeleteProductFromBranch = async () => {
    setIsLoading(true);
    const idLine = params.data.idLine;
    const loginEncrypt = getCookieValue(findCookie('login'));
    const login = decryptWithAES(loginEncrypt);
    const { Email, Password, BussinesUnitParent } = ctCredentials(login);
    const customer = ctCustomer(Email, Password).inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent });
    const shoppingList = await customer.me().shoppingLists().withId({ ID: params.data.idShoppingList }).get().execute();
    if (!shoppingList.statusCode || shoppingList.statusCode >= 300) return notifyError('Algo salio mal');
    const lineItemShopping = shoppingList.body.lineItems.find((list) => list.id === idLine);
    const fieldsItems = lineItemShopping?.custom?.fields['sucursalItemReturn'];
    const fields = fieldsItems.map((item: any) => JSON.parse(item));
    const fieldFilter = fields.filter((itemField: ICustomField) => itemField.idSucursal !== item.id);
    const parseFields = fieldFilter.map((field: any) => JSON.stringify(field));
    if (fields.length == 1) {
      const removeLine = await customer
        .me()
        .shoppingLists()
        .withId({ ID: params.data.idShoppingList })
        .post({
          body: {
            version: shoppingList.body.version,
            actions: [
              {
                action: 'removeLineItem',
                lineItemId: params.data.idLine,
              },
            ],
          },
        })
        .execute();
      const loadBussinesUnit = await apiRoot
        .businessUnits()
        .get({
          queryArgs: {
            limit: 500,
            where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
          },
        })
        .execute();
      if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300) return notifyError('No se cargaron las sucursales');
      const newShoppingList = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
        .me()
        .shoppingLists()
        .withId({ ID: params.data.idShoppingList  })
        .get({
          queryArgs: {
            expand: 'lineItems[*].variant',
          },
        })
        .execute();
      const lines = mapLineItems(newShoppingList.body, loadBussinesUnit.body.results);
      setShoppingListDetails((prevState) => ({ ...prevState, linesItems: lines, shoppingListCT: removeLine.body }));
      setShowModal(false);
      setIsLoading(false);
    } else {
      const updateShoppingList = await customer
        .me()
        .shoppingLists()
        .withId({ ID: params.data.idShoppingList })
        .post({
          body: {
            version: shoppingList.body.version,
            actions: [
              {
                action: 'setLineItemCustomField',
                lineItemId: params.data.idLine,
                name: 'sucursalItemReturn',
                value: parseFields,
              },
            ],
          },
          queryArgs: {
            expand: 'lineItems[*].variant',
          },
        })
        .execute();
      const loadBussinesUnit = await apiRoot
        .businessUnits()
        .get({
          queryArgs: {
            limit: 500,
            where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
          },
        })
        .execute();
      if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300)
        return notifyError('No se cargaron las sucursales');

      const lines = mapLineItems(updateShoppingList.body, loadBussinesUnit.body.results);
      setShoppingListDetails((prevState) => ({
        ...prevState,
        linesItems: lines,
        shoppingListCT: updateShoppingList.body,
      }));
      setShowModal(false);
      setIsLoading(false);
    }
    // router.reload();
  };

  const getHeaderToExport = () => {
    const columns = gridApi.getAllDisplayedColumns();

    return columns.map((column: any) => {
      const { field } = column.getColDef();
      const sort = column.getSort();
      const headerNameUppercase = field[0].toUpperCase() + field.slice(1);
      const headerCell = {
        text: headerNameUppercase + (sort ? ` (${sort})` : ''),
        color: '#fff',
        margin: [10, 12, 10, 0],
        border: [false, false, false, false],
      };
      return headerCell;
    });
  };

  const getRowsToExport = () => {
    const columns = gridApi.getAllDisplayedColumns();
    const getCellToExport = (column: any, node: any) => {
      if (column.colId === 'Producto') {
        return Object.entries(node.data.Producto as Record<string, string>).map(([key, value]: [string, string]) => {
          debugger
          if (key === 'image' || key === 'attributes') return;
          if (key === 'type') return { text: `${value as string}` };
          return { text: `${key.toUpperCase() as string}:  ${value as string}` };
        });
      }

      if (column.colId === 'Precio unitario') {
        return { text: node.data['Precio unitario'], alignment: 'right', margin: [0,0, 20, 0] };
      }

      if (column.colId === 'Sucursal') {
        return node.data.Sucursal.map((item: ItemsProduct) => {
          return { text: item.sucursal };
        });
      }

      if (column.colId === 'Cantidad') {
        return node.data.Sucursal.map((item: ItemsProduct) => {
          return { text: item.Cantidad };
        });
      }

      if (column.colId === 'SubTotal') {
        return node.data.Sucursal.map((item: ItemsProduct) => {
          return { text: item.Subtotal, alignment: 'right', margin: [0,0, 20, 0] };
        });
      }
      if (column.colId === 'Total') {
        return node.data.Sucursal.map((item: ItemsProduct) => {
          return { text: item.Total, alignment: 'right', margin: [0,0, 20, 0] };
        });
      }
    };

    const rowsToExport: any[] = [];
    gridApi.forEachNodeAfterFilterAndSort((node: any) => {
      const rowToExport = columns.map((column: any) => {
        if (column.colId === 'Delete') return '';
        return getCellToExport(column, node);
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  };

  // Row colors
  const HEADER_ROW_COLOR = '#002554';
  const EVEN_ROW_COLOR = '#F7F7F7';
  const ODD_ROW_COLOR = '#fff';

  const createLayout = () => ({
    fillColor: (rowIndex: any) => {
      if (rowIndex < 1) {
        return HEADER_ROW_COLOR;
      }
      return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
    },
    hLineWidth: () => 0, // Ancho del borde horizontal
    vLineWidth: () => 0, //
  });

  const getDocument = () => {
    const columns = gridApi.getAllDisplayedColumns();

    const headerRow = getHeaderToExport();
    headerRow.pop();
    const rows = getRowsToExport().map((r) => {
      const nr = [...r];
      nr.pop();
      return nr;
    });

    const widths = Array.from(Array(headerRow.length)).map(x => "20%")
    return {
      pageMargins: [10, 60, 10, 130],
      pageOrientation: 'landscape', // can also be 'portrait'
      content: [
        {
          table: {
            // the number of header rows
            headerRows: 1,

            // the width of each column, can be an array of widths
            widths,

            // all the rows to display, including the header rows
            body: [headerRow, ...rows],

            // Header row is 40px, other rows are 15px
            heights: (rowIndex: any) => (rowIndex === 0 ? 40 : 15),
          },
          layout: createLayout(),
        },
      ],
      footer: {
        stack: [
          {
            width: '33%',
            text: `Total: ${formatCurrency(subtotal)}`,
            alignment: 'right',
            margin: [0, 0, 20, 5]
          }
        ],
        absolutePosition: { bottom: 130 } // Ajusta la posición vertical según sea necesario
      }
    };
  };

  const exportToPDF = () => {
    const doc = getDocument();
    debugger
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(doc).download();
  };

  useEffect(() => {
    if (selectPrint) {
      exportToPDF();
      setPrint(() => false)
    }
  }, [selectPrint]);

  return (
    <>
      <CustomModal
        label1="¿Estás seguro de querer eliminar el producto?"
        labelButton1="No eliminar producto"
        labelButton2="Sí, eliminar"
        variant={isLoading? "disabled":"secondary"}
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          handleDeleteProductFromBranch();
        }}
        onClick={() => {
          setShowModal(false);
        }}
      >
        <hr />
        <div style={{ textAlign: 'center' }}>El producto se eliminará de tu carrito</div>
      </CustomModal>
      <CustomGrid
        showLegend={false}
        pagination={true}
        autoSizeStrategy={autoSizeStrategy}
        rowHeight={93}
        height={500}
        rowData={rows}
        onGridReady={(params: any) => {
          setGridApi(params.api);
        }}
        // @ts-ignore
        columnDefs={columnDefs}
        // @ts-ignore
        noRowsOverlayComponent={EmptyState}
        noRowsOverlayComponentParams={{
          icon: <ListaComprasEmpty />,
          text: 'Aún no tienes productos en esta lista.',
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', height: '50px', alignItems: 'center' }}>
        {/* <Pagination
          page={pagination.activePage}
          count={Math.ceil(rows.length / (gridApi?.paginationGetPageSize() || 1))}
          color="primary"
          onChange={handlePagination}
        /> */}
      </div>
    </>
  );
};

export default Grid;

