import React, { useState } from 'react'
import styles from './styles.module.scss';
import { ItemsProduct } from './Grid';
import ButtonAdd from '@/shared/components/buttonAdd/ButtonAdd';
import { Attribute } from '@commercetools/platform-sdk';

const ButtonAddPerBranch = ({item,params}:{item: ItemsProduct,params:any}) => {

  const quantityFromCT = item.Cantidad;
  const [quant, setQuant] = useState(quantityFromCT);

  const minimalquantity=params.data.Producto?.attributes?.find((attr:Attribute)=>attr.name==="minimalquantity")?.value ||1
  const incremental=params.data.Producto?.attributes?.find((attr:Attribute)=>attr.name==="incremental")?.value ||1
  
  return (
    <div className={styles['cell']}>
            <div style={{ height: '30px', width: '100%' }}>
              {item?.Cantidad && (
                <ButtonAdd
                  quantity={quant}
                  onAdd={async () => {
                    setQuant(quant + incremental);

                   
                    params.callback_updateQuantityProductBySucursal({
                      businessId: item.id,
                      idLine: params.data.idLine,
                      idShoppingList: params.data.idShoppingList,
                      quantity: quant+incremental,
                      quantityFromCT,
                    });

                    // router.reload();
                  }}
                  onRemove={async () => {
                    let newQuantity=quant-incremental
                    if(newQuantity< minimalquantity) newQuantity=minimalquantity

                    setQuant(Math.max(minimalquantity,newQuantity));
                     params.callback_updateQuantityProductBySucursal({
                      businessId: item.id,
                      idLine: params.data.idLine,
                      idShoppingList: params.data.idShoppingList,
                      quantity: Math.max(minimalquantity,newQuantity),
                      quantityFromCT
                    });
                   
                    // router.reload();
                  }}
                />
              )}
            </div>
          </div>
  )
}

export default ButtonAddPerBranch
