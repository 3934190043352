import React, { useState, useEffect, useRef, useContext } from 'react';
import Link from 'next/link';
import styles from './styles.module.scss';
import Button from '@/shared/components/button/Button';
import Modal from '@/shared/components/modal/Modal';
import { PrintIcon, ArrowDown } from '@/shared/icons';
import Summary from '../Summary/Summary';
import TooltipShopping from './Tooltip';
import Grid from './Grid';
import { useRouter } from 'next/router';
import CONST_REDIRECTS from '@/shared/consts/CONST_REDIRECTS.json';
import MainLayout from '@/shared/components/mainLayout/MainLayout';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { apiRoot, ctCustomer } from '@/shared/client';
import { BusinessUnit, ShoppingList } from '@commercetools/platform-sdk';
import { notifyError, notifySuccess } from '@/shared/components/toast/Toast';
import { formatCurrency } from 'shared/functions/currencyFunctions';
import { addProductsToCartShippingList, getStandalonePriceByBranch } from '@/shared/functions/commerceTools';
import { decryptWithAES } from '@/shared/functions/encrypt';
import CustomDropdown from '@/shared/components/customDropdown/CustomDropdown';
import Loader from '@/shared/components/loader/Loader';
import { Autocomplete, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';

import type {
  ClientResponse,
  ProductProjection,
  ProductProjectionPagedSearchResponse,
} from '@commercetools/platform-sdk';
import { getCredentials } from 'context/appContext/initialLoading';
import { AppContext } from 'context/appContext/AppContextProvider';

import { rol } from '@/shared/roles/featureFlags';

export interface linesItemsRow {
  idLine: string;
  idShoppingList: string;
  image: string;
  Producto: any;
  'Precio unitario': string;
  unitPriceNumber: number;
  Sucursal: any;
  Cantidad: number;
  Subtotal: string;
  IVA: string;
  Descuento: string;
  Total: string;

  price: number;
  listBusiness: any[];
}

export interface ICustomField {
  quantity: string;
  idSucursal: string;
  total: string;
  price: string;
}

interface Option {
  idProduct: string;
  idVariant: string;
  key: string;
  name: string;
  sku?: string;
}

export interface IShoppingListDetails {
  businessUnits: BusinessUnit[];
  linesItems: linesItemsRow[];
  shoppingListCT: ShoppingList;
  subtotalPrice: number;
  ivaPrice: number;
  totalPrice: number;
}

const initialStateShoppingListDetails: IShoppingListDetails = {
  businessUnits: [],
  linesItems: [],
  shoppingListCT: {} as ShoppingList,
  subtotalPrice: 0,
  ivaPrice: 0,
  totalPrice: 0,
};
export const LOCALIZED_STRING = 'es-MX';
const ShoppingListDetail = () => {
  const router = useRouter();
  const context = useContext(AppContext);
  const inputRef = useRef<any>();
  const [{ businessUnits, linesItems, shoppingListCT, subtotalPrice, ivaPrice, totalPrice }, setShoppingListDetails] =
    useState<IShoppingListDetails>(initialStateShoppingListDetails);
  const [input, setInput] = useState<string>('');
  const [selectedBranchId, setSelectedBranchId] = useState<string>('');
  const [selectPrint, setSelectPrint] = useState<boolean>(false);
  const [results, setResults] = useState<ProductProjection[]>();
  const [options, setOptions] = useState<Option[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(()=>{
    if(context.appContext.customer?.custom?.fields && !rol[context.appContext.customer.custom?.fields.roles]["seeShoppingListsDetails"]){
      // router.push(CONST_REDIRECTS.HOME)
      router.back()
    }
  },[context])

  const [{ isLoading, showLoader }, setState] = useState({
    isLoading: false,
    showLoader: false,
  });

  const handleModal = () => {
    setOpenModal(!openModal);
    setSelectedBranchId(() => "")
  };

  const handleProductstoCartFromShoppingList = async () => {
    try {
      setState((props) => ({ ...props, showLoader: true }));
      const shippingListId = router.query.id?.toString() || '';
      const productsRes = await addProductsToCartShippingList({ shippingListId });
      if (!productsRes.cartRes?.cart) {
        notifyError(productsRes.message);
        return;
      }
      context.setCart(productsRes.cartRes.cart);
      // router.push('/cart');
      notifySuccess('Se agregaron los productos al carrito');
    } catch (err: any) {
      return notifyError(err.message);
    } finally {
      setState((props) => ({ ...props, showLoader: false }));
    }
  };

  const mapLineItems = (shoppingList: ShoppingList, bussinessUnits: BusinessUnit[]) => {
    let sum = 0;
    return shoppingList.lineItems.map((lineItem) => {

      const fieldsItems = lineItem.custom?.fields['sucursalItemReturn'];
      const fields = fieldsItems.map((item: any) => JSON.parse(item));
      let priceUnit = 0;
      let subTotal;
      const sucursalItems = fields.map((field: ICustomField) => {
        const getBusinessName = bussinessUnits.find((address) => address.key === field.idSucursal);
        subTotal = formatCurrency(parseInt(field.total) / 100 );
        const elementTotal = parseInt(field.total) / 100 ;
        priceUnit = parseInt(field.price) / 100
        sum = (elementTotal + sum);
        debugger
        setShoppingListDetails((opts) => ({
          ...opts,
          subtotalPrice: sum,
        }));
        return {
          sucursal: getBusinessName?.name,
          Cantidad: field.quantity,
          Descuento: '$0',
          Subtotal: subTotal,
          subTotalNumber: parseInt(field.total) / 100 ,
          Total: formatCurrency(parseInt(field.total) / 100),
          totalNumber: parseInt(field.total) / 100,
          isDelete: 'delete',
          key: getBusinessName?.key,
          id: getBusinessName?.key,
        };
      });
      const listBusiness = bussinessUnits.map((business) => {
        return {
          value: business.id,
          label: business.name,
        };
      });
      const iva = sum * 0.16;
        setShoppingListDetails((opts) => ({
        ...opts,
        ivaPrice: parseFloat(iva.toFixed(2)),
      }));
      return {
        idShoppingList: shoppingList.id,
        idLine: lineItem.id,
        Producto: {
          image: lineItem.variant?.images?.[0]?.url || '/no_econtrada.webp',
          type: lineItem.name?.["es-MX"] || lineItem?.variant?.key,
          sku: lineItem?.variant?.sku,
          attributes:lineItem.variant?.attributes
        },
        'Precio unitario': formatCurrency(priceUnit!),
        unitPriceNumber: priceUnit! / 100 ,
        price: sum,
        Descuento: '$0',
        IVA: '$0',
        Sucursal: sucursalItems,
        listBusiness,
      } as linesItemsRow;
    });
  };

  const getShoppingList = async () => {
    try {
      const listId = router.query.id;
      if (!listId) return router.push(CONST_REDIRECTS.SHOPPING_LIST);
      const loginEncrypt = getCookieValue(findCookie('login'));
      const login = decryptWithAES(loginEncrypt);
      const { Email, Password, BussinesUnitParent, CustomerId } = ctCredentials(login);
      const shoppingList = await ctCustomer(Email, Password)
        .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
        .me()
        .shoppingLists()
        .withId({ ID: listId.toString() })
        .get({
          queryArgs: {
            expand: 'lineItems[*].variant',
          },
        })
        .execute();
      if (!shoppingList.statusCode || shoppingList.statusCode >= 300)
        return notifyError('No se encontro lista de compras');
      // const loadBussinesUnit = await apiRoot
      //   .businessUnits()
      //   .get({
      //     queryArgs: {
      //       limit: 500,
      //       where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
      //     },
      //   })
      //   .execute();
      const loadBussinesUnit = await apiRoot.asAssociate()
                                .withAssociateIdValue({ associateId: CustomerId })
                                .businessUnits()
                                .get({
                                  queryArgs: {
                                    limit: 500,
                                    where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
                                  }
                                })
                                .execute()
      if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300)
        return notifyError('No se cargaron las sucursales');

      const lines = mapLineItems(shoppingList.body, loadBussinesUnit.body.results);
      setShoppingListDetails((opts) => ({
        ...opts,
        linesItems: lines,
        businessUnits: loadBussinesUnit.body.results,
        shoppingListCT: shoppingList.body,
      }));
    } catch (err: any) {
      console.error('error', err);
      return notifyError(err.message);
    }
  };

  const addItemToList = async() => {
    try {
      setState((props) => ({ ...props, showLoader: true, isLoading: true }));
      if (!input || input == '' || selectedBranchId == '') throw new Error('SKU o sucursal están vacías');
      const { Email, Password, BussinesUnitParent } = getCredentials();
      const ct = ctCustomer(Email, Password)
      
      const loadProductsReq= await apiRoot.productProjections().get({
                              queryArgs:{
                                  where:`masterVariant(sku in (${input}))`,
                                  limit:1
                              }}).execute()
      const minimalQuantity = loadProductsReq?.body?.results?.[0]?.masterVariant?.attributes?.find( attr => attr.name == "minimalquantity")?.value || 1
      const incremental= loadProductsReq.body?.results?.[0]?.masterVariant?.attributes?.find(attr=>attr.name==="incremental")?.value || 1
      const price = await getStandalonePriceByBranch({
        sku: input.toString().trim(),
        keyBussinessUnit: selectedBranchId,
        distributionChannel: 'general',
      });
      let shoppingList = shoppingListCT.lineItems.find(item => {
        const jsonS = JSON.parse(item?.custom?.fields["sucursalItemReturn"])
        return item?.variant?.sku == input && jsonS.idSucursal == selectedBranchId
      })
      let version = shoppingListCT.version
      if(!shoppingList) {
        const addLine = await ct
                        .inStoreKeyWithStoreKeyValue({storeKey: BussinesUnitParent })
                        .shoppingLists()
                        .withId({ ID: shoppingListCT.id })
                        .post({
                          body: {
                            version: shoppingListCT.version,
                            actions: [
                              {
                                action: "addLineItem",
                                sku: input,
                                quantity: minimalQuantity,
                              },
                            ]
                          }
                        })
                        .execute()
        shoppingList = addLine?.body?.lineItems?.[addLine.body.lineItems.length -1]
        version = addLine.body.version
      }
      const getLine = shoppingList.id
      const updateShoppingList = await ct
                                .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
                                .shoppingLists()
                                .withId({ ID: shoppingListCT.id })
                                .post({
                                  body: {
                                    version: version,
                                    actions: [
                                      {
                                        action: 'setLineItemCustomType',
                                        lineItemId: getLine,
                                        type: {
                                          key: 'line-item-types',
                                          typeId: 'type',
                                        },
                                        fields: {
                                          sucursalItemReturn: [
                                            JSON.stringify({
                                              idSucursal: selectedBranchId,
                                              total: shoppingList.custom ? JSON.parse(shoppingList?.custom?.fields.sucursalItemReturn[0]).total * JSON.parse(shoppingList?.custom?.fields.sucursalItemReturn[0]).quantity + incremental : price.body.value.centAmount * minimalQuantity,
                                              quantity: shoppingList.custom ? JSON.parse(shoppingList?.custom?.fields.sucursalItemReturn[0]).quantity + incremental : minimalQuantity,
                                              price: price.body.value.centAmount,
                                            }),
                                          ],
                                        },
                                      }
                                    ],
                                  },
                                  queryArgs: {
                                    expand: 'lineItems[*].variant',
                                  },
                                }).execute()
      const newShoppingList = await ctCustomer(Email, Password)
      .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
      .me()
      .shoppingLists()
      .get({
        queryArgs: {
          expand: 'lineItems[*].variant',
        },
      })
      .execute();
      notifySuccess('Se añadio correctamente');
      context.setShoppingLists(newShoppingList.body.results)
      const lines = mapLineItems(updateShoppingList.body, businessUnits);
      debugger
      setShoppingListDetails((prevState) => ({
        ...prevState,
        shoppingListCT: updateShoppingList.body,
        linesItems: lines,
      }));
      setOpenModal(false);
    } catch(err: any) {
      const message = err.message || err
      notifyError(message)
    }
    finally {
      setState((props) => ({ ...props, showLoader: false, isLoading: false }));
    }
  }

  // const addProductToList = async () => {
  //   setState((props) => ({ ...props, showLoader: true, isLoading: true }));
  //   try {
  //     if (!input || input == '' || selectedBranchId == '') return notifyError('SKU o sucursal están vacías');
  //     const { Email, Password, BussinesUnitParent } = getCredentials();
  //     let minimalquantity=1;
  //     //si el producto ya se encuentra en la lista y es de la misma sucursal, se añade +1
  //     const foundLineItemOnListSameBranch = shoppingListCT.lineItems.find((lineItem) => {
  //       const branches = lineItem?.custom?.fields.sucursalItemReturn.map((el: any) => JSON.parse(el));
  //       const foundInBranches = branches.find((el: any) => el.idSucursal === selectedBranchId);
  //       const found = lineItem.variant?.sku === input && foundInBranches;
  //       return found;
  //     });
  //     let lineItemId=""

  //     if (foundLineItemOnListSameBranch) {
  //       const fields=JSON.parse(foundLineItemOnListSameBranch?.custom?.fields.sucursalItemReturn[0])
  //       lineItemId=foundLineItemOnListSameBranch.id
  //       const quantity=fields.quantity
  //       const incremental= foundLineItemOnListSameBranch.variant?.attributes?.find(attr=>attr.name==="incremental")?.value || 1
  //       minimalquantity= foundLineItemOnListSameBranch.variant?.attributes?.find(attr=>attr.name==="minimalquantity")?.value || 1
        
  //       // router.reload();
  //       const price = await getStandalonePriceByBranch({
  //         sku: input.toString().trim(),
  //         keyBussinessUnit: selectedBranchId,
  //         distributionChannel: 'general',
  //       });
  //       const updateItemOnShoppingListRes = await ctCustomer(Email, Password)
  //         .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
  //         .me()
  //         .shoppingLists()
  //         .withId({ ID: shoppingListCT.id })
  //         .post({
  //           body: {
  //             version: shoppingListCT.version,
  //             actions: [
  //               {
  //                 action: 'setLineItemCustomType',
  //                 lineItemId: foundLineItemOnListSameBranch.id,
  //                 type: {
  //                   key: 'line-item-types',
  //                   typeId: 'type',
  //                 },
  //                 fields: {
  //                   sucursalItemReturn: [
  //                     JSON.stringify({
  //                       idSucursal: selectedBranchId,
  //                       total: price.body.value.centAmount || 0,
  //                       quantity: quantity + incremental,
  //                       price: price.body.value.centAmount,
  //                     }),
  //                   ],
  //                 },
  //               }
  //             ],
  //           },
  //           queryArgs: {
  //             expand: 'lineItems[*].variant',
  //           },
  //         })
  //         .execute();
  //       if (updateItemOnShoppingListRes.statusCode && updateItemOnShoppingListRes.statusCode >= 300) {
  //         console.error(updateItemOnShoppingListRes);
  //         notifyError('error al actualizar el producto');
  //         return;
  //       }
  //       const loadBussinesUnit = await apiRoot
  //         .businessUnits()
  //         .get({
  //           queryArgs: {
  //             limit: 500,
  //             where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
  //           },
  //         })
  //         .execute();
  //       if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300)
  //         return notifyError('No se cargaron las sucursales');
  //       notifySuccess('Se añadio correctamente');
  //       const lines = mapLineItems(updateItemOnShoppingListRes.body, loadBussinesUnit.body.results);
  //       setShoppingListDetails((prevState) => ({
  //         ...prevState,
  //         shoppingListCT: updateItemOnShoppingListRes.body,
  //         linesItems: lines,
  //       }));
  //       setSelectedBranchId(() => "")
  //       setInput(() => "")
  //       setOpenModal(() => false);
  //       return;
  //     }else{
  //       const loadProductsReq= await apiRoot.productProjections().get({
  //         queryArgs:{
  //             where:`masterVariant(sku in (${input}))`,
  //             limit:1
  //         }}).execute()
  //       minimalquantity = loadProductsReq.body.results[0].masterVariant.attributes?.find(attr=>attr.name==="minimalquantity")?.value || 1
  //       console.log("loadProductsReq",loadProductsReq)
  //     debugger
  //     }

  //     const addProductList = await ctCustomer(Email, Password)
  //       .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
  //       .me()
  //       .shoppingLists()
  //       .withId({ ID: shoppingListCT.id })
  //       .post({
  //         body: {
  //           version: shoppingListCT.version,
  //           actions: [
  //             {
  //               action: 'addLineItem',
  //               sku: foundLineItemOnListSameBranch ? foundLineItemOnListSameBranch : input,
  //               quantity: minimalquantity
  //             },
  //           ],
  //         },
  //       })
  //       .execute();
  //       console.log(addProductList)
  //       debugger

  //       const incremental:number=addProductList.body.lineItems[addProductList.body.lineItems.length-1].variant?.attributes?.find(attr=>attr.name==="incremental")?.value || 1
  //     if (!addProductList.statusCode || addProductList.statusCode >= 300) return notifyError('Sku no es valido');

  //     let updateProductOnListRes:ClientResponse<ShoppingList>
  //     if(!foundLineItemOnListSameBranch){
  //       lineItemId=addProductList.body.lineItems[addProductList.body.lineItems.length-1].id
  //      debugger
  //      const price = await getStandalonePriceByBranch({
  //       sku: input.toString().trim(),
  //       keyBussinessUnit: selectedBranchId,
  //       distributionChannel: 'general',
  //     });

  //       updateProductOnListRes = await ctCustomer(Email, Password)
  //     .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
  //     .me()
  //     .shoppingLists()
  //     .withId({ ID: shoppingListCT.id })
  //     .post({
  //       body: {
  //         version: addProductList.body.version,
  //         actions: [
  //           {
  //             action: 'changeLineItemQuantity',
  //             quantity: minimalquantity,
  //             lineItemId
  //           },
  //           {
  //             action: 'setLineItemCustomType',
  //             lineItemId,
  //             type: {
  //               key: 'line-item-types',
  //               typeId: 'type',
  //             },
  //             fields: {
  //               sucursalItemReturn: [
  //                 JSON.stringify({
  //                   idSucursal: selectedBranchId,
  //                   total: price.body.value.centAmount || 0,
  //                   quantity: minimalquantity,
  //                   price: price.body.value.centAmount,
  //                 }),
  //               ],
  //             },
  //           }
  //         ],
  //       },
  //     })
  //     .execute();

  //     if (!updateProductOnListRes.statusCode || updateProductOnListRes.statusCode >= 300)  notifyError('error al actualizar cantidad minima')
  //     console.log(updateProductOnListRes)
  //     debugger

  //   }
  //     const getShopping = await ctCustomer(Email, Password)
  //       .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
  //       .me()
  //       .shoppingLists()
  //       .withId({ ID: addProductList.body.id })
  //       .get({
  //         queryArgs: {
  //           expand: 'lineItems[*].variant',
  //         },
  //       })
  //       .execute();
  //     if (!getShopping.statusCode || getShopping.statusCode >= 300) return notifyError('Sku no es valido');
  //     // let line = '';
  //     // getShopping.body.lineItems.forEach((item) => {
  //     //   if (item.variant?.sku?.toString().trim() == input.toString().trim() && !item.custom) {
  //     //     line = item.id;
  //     //   } else {
  //     //     const fieldsItems = item.custom?.fields['sucursalItemReturn'];
  //     //     const fields = fieldsItems.map((item: any) => JSON.parse(item));
  //     //     if (item.variant?.sku?.toString().trim() == input.toString().trim() && fields.idSucursal == selectedBranchId)
  //     //       line = item.id;
  //     //   }
  //     // });

  //     const price = await getStandalonePriceByBranch({
  //       sku: input.toString().trim(),
  //       keyBussinessUnit: selectedBranchId,
  //       distributionChannel: 'general',
  //     });
  //     const addBussinesUnit = await ctCustomer(Email, Password)
  //       .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
  //       .me()
  //       .shoppingLists()
  //       .withId({ ID: getShopping.body.id })
  //       .post({
  //         body: {
  //           version: getShopping.body.version,
  //           actions: [
  //             {
  //               action: 'setLineItemCustomType',
  //               lineItemId,
  //               type: {
  //                 key: 'line-item-types',
  //                 typeId: 'type',
  //               },
  //               fields: {
  //                 sucursalItemReturn: [
  //                   JSON.stringify({
  //                     idSucursal: selectedBranchId,
  //                     total: price.body.value.centAmount || 0,
  //                     quantity: foundLineItemOnListSameBranch? incremental : minimalquantity,
  //                     price: price.body.value.centAmount,
  //                   }),
  //                 ],
  //               },
  //             },
  //           ],
  //         },
  //         queryArgs: {
  //           expand: 'lineItems[*].variant',
  //         },
  //       })
  //       .execute();

  //       console.log(addBussinesUnit)
  //       debugger

  //     if (!addBussinesUnit.statusCode || addBussinesUnit.statusCode >= 300) return notifyError('Algo salio mal');

  //     const loadBussinesUnit = await apiRoot
  //       .businessUnits()
  //       .get({
  //         queryArgs: {
  //           limit: 500,
  //           where: `parentUnit (key in ("${BussinesUnitParent.trim()}"))`,
  //         },
  //       })
  //       .execute();
  //     if (!loadBussinesUnit.statusCode || loadBussinesUnit.statusCode >= 300)
  //       return notifyError('No se cargaron las sucursales');
  //     notifySuccess('Se añadio correctamente');
  //     const lines = mapLineItems(addBussinesUnit.body, loadBussinesUnit.body.results);
  //     console.log(lines)
  //     debugger
  //     setShoppingListDetails((prevState) => ({
  //       ...prevState,
  //       shoppingListCT: addBussinesUnit.body,
  //       linesItems: lines,
  //     }));
  //     setOpenModal(false);
  //   } catch (err: any) {
  //     if (err.message?.includes('No published')) {
  //       return notifyError('el SKU no es valido');
  //     }
  //     return notifyError(err.message);
  //   } finally {
  //     setState((props) => ({ ...props, showLoader: false, isLoading: false }));
  //   }
  // };

  const sdk_getProducts = (
    querySearch: string,
  ): Promise<ClientResponse<ProductProjectionPagedSearchResponse> | string> => {
    // const loginEncrypt = getCookieValue(findCookie('login'));
    // const login = decryptWithAES(loginEncrypt);
    // const { Email, Password } = ctCredentials(login);

    return new Promise(async (resolve, reject) => {
      if (typeof querySearch !== 'string') return reject('');
      if (!querySearch?.trim()) return reject('');

      let fuzzyLevel: number = 0;
      if (querySearch.length > 5) fuzzyLevel = 2;
      if (querySearch.length >= 3 && querySearch.length < 5) fuzzyLevel = 1;
      if (querySearch.length < 3) fuzzyLevel = 0;

      try {
        const resProducts = await apiRoot
          .productProjections()
          .search()
          .get({
            queryArgs: {
              'text.es-MX': querySearch,
              fuzzy: true,
              fuzzyLevel,
            },
          })
          .execute();

        resolve(resProducts);
      } catch (e: any) {
        reject('error: ' + e.message);
      }
    });
  };

  let idTimeout: any;
  const handleInputChange = (e: any) => {
    // @ts-ignore
    clearTimeout(idTimeout);

    // @ts-ignore
    idTimeout = setTimeout(async () => {
      const res = await sdk_getProducts(e.target.value);
      if (typeof res === 'string') {
        notifyError('error al obtener productos: ' + res);
        return;
      }
      if (res.statusCode !== 200) {
        notifyError('error al obtener productos');
        return;
      }
      setResults(res.body.results);
    }, 400);
  };

  useEffect(() => {
    getShoppingList();
  }, []);

  useEffect(() => {
    if (results?.length === 0 || !results) return;

    let newOptions: Option[] = [];

    results.forEach((product) => {
      if (product.masterVariant.key)
        newOptions.push({
          idProduct: product.id.toString(),
          idVariant: product.masterVariant.id.toString(),
          key: product.masterVariant.key,
          name: product?.name?.["es-MX"] || product.key || "",
          sku: product.masterVariant.sku,
        });

      product.variants.forEach((variant) => {
        if (variant.key)
          newOptions.push({
            idProduct: product.id,
            idVariant: variant.id.toString(),
            key: variant.key,
            name: variant.attributes?.find((attr) => attr.name === 'name')?.value || product.key,
            sku: variant.sku,
          });
      });
    });

    setOptions(newOptions);
  }, [results]);

  const callback_onNameUpdate = (idList: string, newName: string, shoppingList: ShoppingList) => {
    const shoppingListsFromContext = context.appContext.shoppingLists;
    const foundShoppingListIndex = shoppingListsFromContext.findIndex((listContext) => listContext.id === idList);
    if (foundShoppingListIndex === -1) {
      notifyError('no se encontro la lista con id ' + idList);
      return;
    }
    const newShoppingLists = [...shoppingListsFromContext];
    newShoppingLists[foundShoppingListIndex] = {
      ...shoppingListsFromContext[foundShoppingListIndex],
      name: { [LOCALIZED_STRING]: newName },
    };
    setShoppingListDetails((prevState) => ({
      ...prevState,
      shoppingListCT: {
        ...prevState.shoppingListCT,
        name: { [LOCALIZED_STRING]: newName },
        version: shoppingList.version,
      },
    }));
    context.setShoppingLists(newShoppingLists);
    router.replace(`/list?id=${idList}`);
  };
  const callback_onListDelete = (idList: string) => {
    const shoppingListsFromContext = context.appContext.shoppingLists;
    const newShoppingLists = shoppingListsFromContext.filter((listContext) => listContext.id !== idList);
    context.setShoppingLists(newShoppingLists);
  };

  return (
    <MainLayout>
      {showLoader ? <Loader message="Agregando productos" /> : <></>}
      <div className={styles['shopping-list']}>
        <div className={styles['container-link']}>
          <Link href="shoppinglist">{'< Volver'}</Link>
        </div>
        <div className={styles['shopping-list-header']}>
          <div className={styles['shopping-list-title']}>
            <div className={styles['shopping-list-container']}>
              <h1>{shoppingListCT.name?.[LOCALIZED_STRING]}</h1>
              <ArrowDown />
            </div>
            <p>({shoppingListCT.lineItems?.length} artículos)</p>
            <div className={styles['shopping-list-tooltip']}>
              <TooltipShopping
                id={shoppingListCT?.id}
                version={shoppingListCT?.version}
                callback_onListDelete={callback_onListDelete}
                callback_onNameUpdate={callback_onNameUpdate}
              />
            </div>
          </div>
          <div className={styles['shopping-list-button']}>
            <Button onClick={() => setSelectPrint(true)} variant="link">
              Imprimir lista <PrintIcon />
            </Button>
            <Button onClick={handleModal}>Agregar productos</Button>
            <Modal
              style={{ color: '#4F4F4F' }}
              label1="Agregar artículo"
              labelButton1="Añadir a la lista"
              labelButton2="Cancelar"
              variant={isLoading ? 'disabled' : undefined}
              open={openModal}
              onClick={()=>{
                if(context.appContext.customer?.custom?.fields && !rol[context.appContext.customer.custom?.fields.roles]["createShoppingListDetails"]){
                  notifyError("no tienes permisos suficientes para modificar una lista de compras")
                  return
                }

                addItemToList()
              }}
              onClose={handleModal}
            >
              <hr />
              <div
                style={{
                  color: '#002554',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                Selecciona una sucursal
              </div>
              <div style={{ marginBottom: '5px' }}>
                <CustomDropdown
                  onChange={async (e: any) => {
                    setSelectedBranchId(e.target.value);
                  }}
                  items={businessUnits.map((bu) => ({ label: bu.name || '', value: bu.key }))}
                  name={'addSucursal-main'}
                  label="Añadir sucursal"
                />
              </div>
              <br />
              <div className={styles['shopping-list-input']}>
                {/* <Search placeholder="Buscar" label="Nombre de producto o SKU" onChange={(e) => handleChangeInput(e)} /> */}
                <Autocomplete
                  PaperComponent={({ children }) => <Paper style={{ width: '403px' }}>{children}</Paper>}
                  ref={inputRef}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  noOptionsText="No hay opciones"
                  onInputChange={handleInputChange}
                  // filterOptions={(options) => options}
                  getOptionLabel={(option: Option) =>
                    `${option.sku} - ${option.name}` || `${option.sku} - ${option.key}`
                  }
                  onChange={(_: any, val: any) => {
                    if (val?.sku) setInput(val.sku);
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField style={{ width: '100%' }} {...params} label="Buscar" />}
                />
              </div>
            </Modal>
            <Button onClick={() => handleProductstoCartFromShoppingList()} variant="secondary">
              {linesItems.length === 0 ? 'Crear orden' : 'Añadir a carrito'}
            </Button>
          </div>
        </div>
        <div className={styles['shopping-list-grid']}>
          <Grid rows={linesItems} selectPrint={selectPrint}  setPrint={setSelectPrint} subtotal={subtotalPrice} iva={ivaPrice} setShoppingListDetails={setShoppingListDetails} />
        </div>
        <div className={styles['shopping-list-summary']}>
            <div>
                <p>Total</p>
                <p style={{ color: '#002554', textAlign: 'right', fontWeight: 'bold' }}>{formatCurrency(subtotalPrice || 0)}</p>
             </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ShoppingListDetail;
