import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styles from './styles.module.scss';
import {IInput} from '../../interfaces/IInput';
import SearchIcon from '@/shared/icons/SearchIcon';

const Input = ({
  label,
  placeholder,
  type = 'text',
  className,
  onChange,
  style,
  icon = false,
  order = false
}: IInput) => {

  return (
    <div className={!order? styles['input-container'] : styles['input-orders']} style={style}>
      {label && <label>{label}</label>}
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      />
      {icon && <SearchIcon className={styles['input-container-icon']}/> }
    </div>
  );
};

export default Input;
