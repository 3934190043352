/**
 * @param {Number} aNumber a number which can be formatted to currency
 * @param {Number} toFixed number of positions right to the decimal
 * @param {String} sign number of positions right to the decimal
 * @description given a number, it formats to a currency string
 * @returns a new String with sign that corresponds of a currency
 * @author Sergio Salazar Ordaz
 * @version 1.0.0
 */
export function formatCurrency(aNumber, toFixed = 2, sign = "$") {
  let formattedNumber = null;
  if (typeof aNumber === "number") {
    formattedNumber =
      sign +
      aNumber.toLocaleString(undefined, {
        minimumFractionDigits: toFixed,
        maximumFractionDigits: toFixed,
      });
  }
  return formattedNumber;
}
