import { useContext, useState } from 'react';
import styles from './Tooltip.module.scss';
import Button from '@/shared/components/button/Button';
import Modal from '@/shared/components/modal/Modal';
import Input from '@/shared/components/input/Input';
import { UpdateIcon } from '@/shared/icons';
import DeleteIcon from '@/shared/icons/DeleteIcon';
import { useRouter } from 'next/router';
import { findCookie, getCookieValue } from '@/shared/functions/cookies';
import { ctCredentials } from '@/shared/functions/ctCredentiales';
import { notifyError } from '@/shared/components/toast/Toast';
import { ctCustomer } from '@/shared/client';
import { decryptWithAES } from '@/shared/functions/encrypt';
import { ShoppingList } from '@commercetools/platform-sdk';
import { LOCALIZED_STRING } from './ShoppingListDetails';

const Tooltip = ({ id, version,callback_onNameUpdate,callback_onListDelete }: { id: string; version: number,callback_onNameUpdate:(idList:string,newName:string,shoppingList:ShoppingList)=>void,callback_onListDelete:(idList:string)=>void }) => {
    const [openModalDeleteList, setOpenModalDeleteList] = useState(false);
    const [openModalUpdateName, setOpenModalUpdateName] = useState(false);
    const [isLoading,setIsLoading]=useState(false)
    const [inputText, setInputText] = useState<string>('');
    const router = useRouter();

    const handleOnChangeInput = (e: any) => {
        setInputText(e.target.value);
    };

    const changeNameList = async () => {
        setIsLoading(true)
        try {
            const loginEncrypt = getCookieValue(findCookie('login'));
            const login = decryptWithAES(loginEncrypt);
            const { Email, Password, BussinesUnitParent } = ctCredentials(login);
            const changeNameListRes = await ctCustomer(Email, Password)
                .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
                .me()
                .shoppingLists()
                .withId({ ID: id })
                .post({
                    body: {
                        version,
                        actions: [
                            {
                                action: 'changeName',
                                name: {
                                    [LOCALIZED_STRING]: inputText,
                                },
                            },
                        ],
                    },
                })
                .execute();
            if (!changeNameListRes.statusCode || changeNameListRes.statusCode >= 300)
              {  
                notifyError('No se ha logrado renombrar tu lista')
                setIsLoading(false)
                return 
            }
            // router.reload();

            callback_onNameUpdate(id,inputText,changeNameListRes.body)
            setIsLoading(false)
            setOpenModalUpdateName(false)
        } catch (err: any) {
            return notifyError(err.message);
        }
    };

    const deleteList = async () => {
        setIsLoading(true)
        try {
            const loginEncrypt = getCookieValue(findCookie('login'));
            const login = decryptWithAES(loginEncrypt);
            const { Email, Password, BussinesUnitParent } = ctCredentials(login);
            const deleteListRes = await ctCustomer(Email, Password)
                .inStoreKeyWithStoreKeyValue({ storeKey: BussinesUnitParent })
                .me()
                .shoppingLists()
                .withId({ ID: id })
                .delete({
                    queryArgs: {
                        version,
                    },
                })
                .execute();
            if (!deleteListRes.statusCode || deleteListRes.statusCode >= 300)
                return notifyError('La lista no se ha podido eliminar');
            router.push('/shoppinglist');
            callback_onListDelete(id)
            setIsLoading(false)
            setOpenModalDeleteList(false)
        } catch (err: any) {
            setIsLoading(false)
            notifyError(err.message);
            return 

        }
    };

    const handleModalDeleteList = () => {
        setOpenModalDeleteList(!openModalDeleteList);
    };

    const handleModalUpdateNameList = () => {
        setOpenModalUpdateName(!openModalUpdateName);
    };

    return (
        <div className={styles['tooltip']}>
            <Button onClick={handleModalUpdateNameList} variant="linkLista">
                <UpdateIcon />
                Cambiar nombre
            </Button>
            <Modal
                label1="Edita el nombre de tu lista"
                labelButton1="Guardar cambios"
                labelButton2="Cancelar"
                variant={isLoading? "disabled":"secondary"}
                open={openModalUpdateName}
                onClick={changeNameList}
                onClose={handleModalUpdateNameList}
            >
                <hr />
                <Input
                    style={{ width: '100%' }}
                    label="Nombre de lista"
                    placeholder="Nombre de lista"
                    onChange={(e) => handleOnChangeInput(e)}
                />
            </Modal>
            <Button onClick={handleModalDeleteList} variant="linkLista">
                <div className={styles['item-tooltip-svg']}>
                    <DeleteIcon />
                </div>
                Eliminar lista
            </Button>
            <Modal
                label1="¿Estás seguro de querer eliminar la lista?"
                labelButton1="No eliminar la lista"
                labelButton2="Sí, eliminar lista"
                variant={isLoading? "disabled":"secondary"}
                open={openModalDeleteList}
                onClick={() => handleModalDeleteList()}
                onClose={() => deleteList()}
            >
                <hr />
                <p className={styles['shopping-list-description']}>Esta acción no se puede deshacer.</p>
            </Modal>
        </div>
    );
};

export default Tooltip;
